import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TO_PRODUCT } from '../helpers/routesConstants';
import {convertToLowerCaseWithDash,limitChar,strip} from '../helpers/utility'
import { IMAGE_BASE_URL } from '../helpers/constants';
const ProductTemp = ({ products, formattedCategory }) => (
    
    <div className="products products-big">
        {products.map(product => (
            product.CategoryIDs && product.HideProduct !== 'Y' && <div title={product.ProductName} key={product.ProductID} className="product row" style={{marginBottom: "10px"}}>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 padding-mobile-zero">
                    <div className='image'>
                        <Link to={formattedCategory ? `${TO_PRODUCT}${formattedCategory}/${product.ProductCode.toLowerCase()}` : `${TO_PRODUCT}${product.ProductCode.toLowerCase()}`}>
                            <img src={IMAGE_BASE_URL + product.ProductPhotoURL} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/img/no-image.gif"}} alt="" className="img-fluid image1" id="check2"/>
                        </Link>
                    </div>
                </div>
                <div className="text-start col-lg-9 col-xl-9 col-md-9 col-sm-9 col-9">
                    <div className='text product-min-height'>  
                        <h3 className="  h5 text-start"><Link to={TO_PRODUCT + product.ProductCode.toLowerCase()}>
                            {/* <Truncate lines="2" ellipsis={<span> ...</span>}> */}
                            { limitChar(product.ProductName,100)}
                            {/* </Truncate> */}
                            </Link>
                        </h3>
                    </div>
                    <p className="h6 text-sm-start" style={{color:'rgb(33, 37, 41)'}}>
                        { limitChar(product.ProductCode && product.ProductCode,100)}
                    </p>
                    {/* <p className="price">
                        {product.Discount > 0 && (
                            <del>
                                <NumberFormat decimalScale={2} value={product.ProductPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </del>
                        )}
                        <NumberFormat decimalScale={2} value={product.ProductPrice - ((product.ProductPrice / 100) * product.Discount)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </p> */}
                    <div className="description text-start">
                        <p title={strip(product.ProductDescriptionShort || '')}>
                        {limitChar(strip(product.ProductDescriptionShort || ''),175)}
                            </p>
                    </div>
                </div>
                {product.Discount > 0 && (
                    <div className="ribbon-holder">
                        <div className="ribbon sale">{product.Discount}% OFF</div>
                    </div>
                )}
            </div>
        ))}
    </div>
);

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedCategory: null,
            isUpdated: false,
        }
    }
    componentDidUpdate(prevProps) {
        const {isUpdated} = this.state;
        const selectedCategory = JSON.parse(localStorage.getItem('selectedCategory'));
        if(!isUpdated)
        {
            const formattedCategory = selectedCategory && selectedCategory.name && convertToLowerCaseWithDash(selectedCategory.name);
            this.setState({ formattedCategory: formattedCategory, isUpdated: true });
        }
    }
    componentWillUnmount() {
        this.setState({isUpdated: false });
    }
    render() {
        const {formattedCategory} = this.state;
        return (
            <div>
                <ProductTemp products={this.props.products} formattedCategory={formattedCategory} />
            </div>
        );
    }
}


export default Product;
