import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import CustomerSectionTabs from '../../components/customer-section-tabs'
import { customerOrdersBreadCrumb, DATE_FORMAT } from '../../helpers/constants';
import Link from 'react-router-dom/Link';
import { TO_STORE, TO_LOGIN, TO_CUSTOMER_ORDER_VIEW } from '../../helpers/routesConstants';
import { PREPARE_ORDER } from '../../helpers/actionConstants';
import store from '../../store';
import {generateBreadcrumbs} from '../../helpers/utility';
var dateFormat = require('dateformat');
const OrderRows = ({ orders }) => (
    <Fragment>
        {(orders.length > 0 && orders.map(order => (
            <tr key={order.OrderID}>
                <th># {order.OrderID}</th>
                <td>{(order.OrderDate !== null && (dateFormat(order.OrderDate, DATE_FORMAT))) || 'N/A'}</td>
                <td>{order.PaymentAmount !== null && (<NumberFormat isNumericString={true} decimalScale={2} value={order.PaymentAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} />) || '$0.00'}</td>
                <td><span className=
                    {
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'cancelled' && "badge badge-danger") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'returned' && "badge badge-danger") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'new' && "badge badge-success") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'partially shipped' && "badge badge-info") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'shipped' && "badge badge-info") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'backordered' && "badge badge-warning") ||
                        (order.OrderStatus !== null && order.OrderStatus.toLowerCase() === 'hold' && "badge badge-warning")
                    }
                >{order.OrderStatus || 'N/A'}</span></td>
                <td><Link to={TO_CUSTOMER_ORDER_VIEW + order.OrderID} className="btn btn-template-outlined btn-sm">View</Link></td>
            </tr>
        ))) || (
                <tr>
                    <th colSpan='5' className="text-center"> No orders found, go to <Link to={TO_STORE}>Store</Link> to place an order</th>
                </tr>
            )}
    </Fragment>
);
class CustomerOrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        }
        this.handleResize = this.handleResize.bind(this);
    }
    handleResize() {
        this.setState({
          isMobile: window.innerWidth <= 1199
        });
    }
    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.checkLoginAndRedirect();
        store.dispatch({type:PREPARE_ORDER,payload:{}});
    }
    checkLoginAndRedirect = () => {
        if (!this.props.isLoggedIn) {
            const link = document.createElement('a');
            link.href = `${TO_LOGIN}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);    
        }
    };
    componentDidUpdate(prevProps) {
        if (!this.props.isLoggedIn) {
            this.props.history.push(TO_LOGIN)
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    render() {
        const {isMobile} = this.state;
        // console.log(this.props.orders)
        return (
            <div id="content">
                <div className="container">
                    <div className="row bar mb-0 flex-row-reverse-colum">
                        <div id="customer-orders" className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {!isMobile && ( <p className="product-map-arrow">{generateBreadcrumbs(customerOrdersBreadCrumb)}</p> )}
                            <p className="text-muted lead">If you have any questions, please feel free to <a href="mailto:icscontactus@intrepidcs.com">contact us</a>, our customer service center is working for you 24/7.</p>
                            <div className="box mt-0 mb-lg-0 box-layout">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead >
                                            <tr className='customize-border'>
                                                <th>Order</th>
                                                <th>Date</th>
                                                <th>Total</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <OrderRows orders={this.props.orders} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 mt-md-0">
                            <CustomerSectionTabs history={this.props.history} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const stateMap = (state) => {
    return {
        orders: state.order.orders,
        inProcess: state.global.inProcess,
        orderError: state.global.orderError,
        isLoggedIn: state.global.isLoggedIn,
    };
};

export default connect(stateMap)(CustomerOrderContainer);
