// Cart Actions
export const TO_HOME = '/';
export const TO_STORE = '/store';
export const TO_CART = '/cart';
export const TO_GET_A_QUOTE = 'https://intrepidcs.com/sales';
export const TO_CHECKOUT_USER_DETAIL = '/checkout-shipping-address';
export const TO_CHECKOUT_BILLING_ADDRESS = '/checkout-billing-address';
export const TO_EXTERNAL_CHECKOUT_BILLING_ADDRESS = '/external-checkout-billing-address/';
export const TO_CHECKOUT_SHIPPING = '/checkout-shipping-detail';
export const TO_CHECKOUT_PAYMENT = '/checkout-payment-detail';
export const TO_CHECKOUT_CONFIRM = '/checkout-confirm';
export const TO_CUSTOMER = '/customer';
export const TO_CUSTOMER_ACCOUNT = '/customer/account';
export const TO_CUSTOMER_ORDERS = '/customer/orders';
export const TO_CUSTOMER_ORDER_VIEW = '/customer/order/';
export const TO_CHANGE_PASSWORD = '/customer/change-password';
export const TO_RESET_PASSWORD = '/customer/reset-password';
export const TO_PASSWORD_CONFIRM = '/customer/confirm-password/';
export const TO_REGISTER = '/register';
export const TO_LOGIN = '/login';
export const TO_LOGOUT = '/logout';
export const TO_PRODUCT = '/product/';
export const TO_CATEGORY = '/category/products/';
export const TO_CONFIRM = '/email/confirm/';
export const TO_EXPIRE = '/email/link-expire/';
export const TO_EXTERNAL_CHECKOUT = '/external-checkout/';
export const TO_RETURN = '/returns/';
export const TO_SHIPPING = '/shipping/';
export const TO_FAQ = '/faq/';
export const TO_ORDER_FAQ_ANSWERS = '/order-faq-ansewers/';
export const TO_SHIP_FAQ_ANSWERS = '/ship-faq-ansewers/';
export const TO_ACCOUNT_FAQ_ANSWERS = '/account-faq-ansewers/';
export const TO_GUARENTEE_FAQ_ANSWERS = '/guarentee-faq-ansewers/';
export const TO_PB_FAQ_ANSWERS = '/pb-faq-ansewers/';
export const TO_GUIDE_FAQ_ANSWERS = '/guide-faq-ansewers/';
export const TO_SUPPORT_FAQ_ANSWERS = '/support-faq-ansewers/';
export const TO_ABOUTUS = '/about-us/';
export const TO_TERM_AND_CONDITION = '/term-and-condition/';
export const TO_PRICES_CART_FAQ_ANSWER = '/cant-see-prices/';
export const WWW_LINK = 'https://www.intrepidcs.com/worldwide/';
export const TO_PRIVACY_POLICY = '/privacy-policy/';
export const IP_STACK_URL = 'https://api.ipstack.com/check?access_key='
export const TO_PRODUCT_DETAIL_ASP = "/ProductDetails.asp";
export const TO_PRODUCT_DETAIL_POPUP_ASP = "/productdetails_popup.asp";
export const TO_EMAILA_FRIEND_ASP = "/EmailaFriend.asp";
export const IP_API_URL = 'https://ipapi.co/';
//Action Types